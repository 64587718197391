import { Provider } from '@angular/core';
import { abiosConfigName } from '@bcf-v2-configurators/tokens/abios/lvbet/abios-config-name';
import { ABIOS_CONFIG_NAME } from '@bcf-v2-configurators/tokens/abios/token';
import { appMaintenanceUrl } from '@bcf-v2-configurators/tokens/app-maintenance/lvbetcom/app-maintenance-url';
import { APP_MAINTENANCE_URL } from '@bcf-v2-configurators/tokens/app-maintenance/token';
import { payoutDetailsEnabled } from '@bcf-v2-configurators/tokens/betslip/lvbetcom/payout-details-enabled';
import { PAYOUT_DETAILS_ENABLED } from '@bcf-v2-configurators/tokens/betslip/token';
import { casinoLink, liveCasinoLink } from '@bcf-v2-configurators/tokens/casino-links/lvbetcom/casino-links';
import { CASINO_FULL_URL, LIVE_CASINO_FULL_URL } from '@bcf-v2-configurators/tokens/casino-links/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetcom/footer-brand-name';
import { FOOTER_BRAND_NAME } from '@bcf-v2-configurators/tokens/footer/token';
import { imgWebConfig } from '@bcf-v2-configurators/tokens/img-webp/lvbetcom/img-web-config';
import { IMG_WEB_CONFIG } from '@bcf-v2-configurators/tokens/img-webp/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetcom/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvbetcomLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/lvbetcom/lvbetcom-language-switcher-flag-map';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { lotteryBrandName } from '@bcf-v2-configurators/tokens/lottery/lvbet/lottery-brand-name';
import { LOTTERY_BRAND_NAME } from '@bcf-v2-configurators/tokens/lottery/token';
import { lvisionBetBoosterApiKeys } from '@bcf-v2-configurators/tokens/lvision-bet-booster/lvbetcom/lvision-bet-booster-api-keys';
import { LVISION_BET_BOOSTER_API_KEYS } from '@bcf-v2-configurators/tokens/lvision-bet-booster/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';
import { statscoreVizuConfigMobile } from '@bcf-v2-configurators/tokens/statscore-vizu-config/lvbet/statscore-vizu-config';
import { STATSCORE_VIZU_CONFIG } from '@bcf-v2-configurators/tokens/statscore-vizu-config/token';

export const settingsProviders: Provider[] = [
  {
    provide: IMG_WEB_CONFIG,
    useValue: imgWebConfig
  },
  {
    provide: APP_MAINTENANCE_URL,
    useValue: appMaintenanceUrl
  },
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetcom/mobile-web/i18n-desc'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: lvbetcomLanguageSwitcherFlagMap
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: STATSCORE_VIZU_CONFIG,
    useValue: statscoreVizuConfigMobile
  },
  {
    provide: ABIOS_CONFIG_NAME,
    useValue: abiosConfigName
  },
  {
    provide: CASINO_FULL_URL,
    useValue: casinoLink
  },
  {
    provide: LIVE_CASINO_FULL_URL,
    useValue: liveCasinoLink
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: LVISION_BET_BOOSTER_API_KEYS,
    useValue: lvisionBetBoosterApiKeys
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/lvbetcom/sportsbook/mobile-web/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  },
  {
    provide: LOTTERY_BRAND_NAME,
    useValue: lotteryBrandName
  },
  {
    provide: PAYOUT_DETAILS_ENABLED,
    useValue: payoutDetailsEnabled
  }
];
